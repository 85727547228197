import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";
import { OtherCover, PdfPreflight } from "../pdfPreflight.interface";
import { useTranslation } from "react-i18next";
import { ConsoPerFace } from "./calculator/roiResult";
import { CoverageType, OpvType } from "../calculatorTypes";
import { useParams } from "react-router-dom";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

const Result = ({
  job,
  inkCost,
  conso,
  setCoverage,
  opvA,
  opvB,
  hasOpv,
  opvType,
  sheetNumber,
  foilList,
  association,
}: {
  job: PdfPreflight;
  inkCost: number;
  conso: ConsoPerFace;
  setCoverage: React.Dispatch<CoverageType>;
  opvA: boolean;
  opvB: boolean;
  hasOpv: boolean;
  opvType: OpvType;
  sheetNumber: number;
  foilList: string[];
  association:
    | {
        varnish: string | undefined;
        antifoil: string | undefined;
      }
    | undefined;
}) => {
  const { t } = useTranslation();

  const { machineType } = useParams<{
    machineType: MachineModelType | undefined;
  }>();

  const otherColorsArray = job.pdfPreflightResponse?.map((o) => {
    const array: { name: string; percent: number; page: number }[] = [];
    machineType === "ALPHAJET" &&
      o.otherColor.forEach(
        (c) =>
          (foilList
            .map((f) => f.toUpperCase())
            .includes(c.name.toUpperCase()) ||
            c.name.toUpperCase().includes("VARNISH") ||
            c.name.toUpperCase().includes("VERNIS")) &&
          array.push({
            name:
              c.name +
              (foilList
                .map((f) => f.toUpperCase())
                .includes(c.name.toUpperCase())
                ? " (FOIL)"
                : ""),
            percent: c.percent,
            page: o.page,
          })
      );

    const antifoilLayer = o.otherColor.find(
      (e) => e.name === association?.antifoil
    );
    if (association?.antifoil && machineType === "COMPACT") {
      array.push({
        name: "ANTIFOIL",
        percent: antifoilLayer?.percent ?? 0,
        page: o.page,
      });
    }

    const varnishLayer = o.otherColor.find(
      (e) => e.name === association?.varnish
    );
    const index = array.findIndex((e) =>
      e.name.toUpperCase().includes("VARNISH")
    );

    if (association?.varnish && machineType === "COMPACT") {
      index !== -1 && array.splice(index, 1);
      array.push({
        name: "varnish",
        percent: varnishLayer?.percent ?? 0,
        page: o.page,
      });
    }

    return array;
  });

  function finalColorArray() {
    const otherColorsFlat = otherColorsArray?.flat();
    const foilArray = otherColorsFlat?.filter((e) =>
      e.name.toUpperCase().includes("FOIL")
    );
    const varnishArray = otherColorsFlat?.filter(
      (e) =>
        e.name.toUpperCase().includes("VARNISH") ||
        e.name.toUpperCase().includes("VERNIS")
    );

    const array: {
      name: string;
      p1: number | undefined;
      p2: number | undefined;
    }[] = [];

    foilArray?.forEach((foil) => {
      if (foilArray?.length) {
        array.push({
          name: foil.name,
          p1: foil.page === 1 ? foil.percent : 0,
          p2: foil.page === 2 ? foil.percent : 0,
        });
      }
    });

    const varnish = {
      name: "Vernis",
      p1: varnishArray?.find((v) => v.page === 1)?.percent,
      p2: varnishArray?.find((v) => v.page === 2)?.percent,
    };

    if (varnishArray?.length) array.push(varnish);

    return array;
  }

  const opvPercent = job.pdfPreflightResponse
    ?.flat()
    .map((n) =>
      n?.otherColor.filter((m) => m.name.toUpperCase().includes("OPV"))
    )
    .flat();

  const otherColor = finalColorArray();

  function round(num: number) {
    return Math.round(num * 1000) / 1000;
  }

  const [checkedColor] = useState<OtherCover[]>([]);
  const [checkedColorP2] = useState<OtherCover[]>([]);

  const sumCMYK = useCallback(
    (job: PdfPreflight, page: number): number => {
      const pdfReponse = job.pdfPreflightResponse?.find((f) => f.page === page);
      if (pdfReponse && job.pdfPreflightResponse) {
        const sumColor =
          page === 1
            ? checkedColor.map((c) => c.percent * 0.6)
            : checkedColorP2.map((c) => c.percent * 0.6);

        return round(
          pdfReponse.CMYKCoverage.Cyan * 0.6 +
            pdfReponse.CMYKCoverage.Magenta * 0.65 +
            pdfReponse.CMYKCoverage.Yellow * 0.63 +
            pdfReponse.CMYKCoverage.Black * 0.6 +
            sumColor.reduce((last, cur) => last + cur, 0)
        );
      }
      return 0;
    },
    [checkedColor, checkedColorP2]
  );

  const [totalCMYKP1, setTotalCMYKP1] = useState(sumCMYK(job, 1));
  const [totalCMYKP2, setTotalCMYKP2] = useState(sumCMYK(job, 2));

  useEffect(() => {
    setTotalCMYKP1(sumCMYK(job, 1));
  }, [job, sumCMYK]);

  useEffect(() => {
    setTotalCMYKP2(sumCMYK(job, 2));
  }, [job, sumCMYK]);

  const getMlForInk = useCallback(
    (i: number, ink: number) => {
      return (i === 0 && totalCMYKP1) || (i === 1 && totalCMYKP2)
        ? (((ink * 100) / (i === 0 ? totalCMYKP1 : totalCMYKP2)) *
            (i === 0 ? conso.front.cmyk : conso.back.cmyk) *
            1000) /
            100
        : 0;
    },
    [conso.back.cmyk, conso.front.cmyk, totalCMYKP1, totalCMYKP2]
  );

  const getMlForEnnoblishment = useCallback(
    (face: number, name: string) => {
      if (
        name.toUpperCase().includes("FOIL") &&
        !name.toUpperCase().includes("ANTIFOIL")
      ) {
        return round(
          (face === 0
            ? conso.front.foil.find((f) => f.name + " (FOIL)" === name)
                ?.value ?? 0
            : conso.back.foil.find((f) => f.name + " (FOIL)" === name)?.value ??
              0) * 1000
        );
      } else {
        switch (name) {
          case "Vernis":
            return round(
              (face === 0 ? conso.front.varnish : conso.back.varnish) * 1000
            );
          case "ANTIFOIL":
            return round((conso.front.antifoil ?? 0) * 1000);
          case "OPV":
            return round(
              (face === 0 ? conso.front.opv : conso.back.opv) * 1000
            );
          default:
            return (
              (face === 0 ? conso.front.varnish : conso.back.varnish) * 1000
            );
        }
      }
    },
    [
      conso.back.foil,
      conso.back.opv,
      conso.back.varnish,
      conso.front.antifoil,
      conso.front.foil,
      conso.front.opv,
      conso.front.varnish,
    ]
  );

  useEffect(
    () => {
      if (job.pdfPreflightResponse) {
        let cmykBack = {
          cyan: { percent: 0, ml: 0 },
          magenta: { percent: 0, ml: 0 },
          yellow: { percent: 0, ml: 0 },
          black: { percent: 0, ml: 0 },
        };
        let varnishFront = { percent: 0, ml: 0 };
        let antifoilCoverage = { percent: 0, ml: 0 };
        let foilFront: { percent: number; ml: number }[] = [];
        let opvFront = { percent: 0, ml: 0 };
        let varnishBack = { percent: 0, ml: 0 };
        let foilBack: { percent: number; ml: number }[] = [];
        let opvBack = { percent: 0, ml: 0 };

        const varnish = otherColor.find(
          (f) =>
            f.name.toUpperCase().includes("VARNISH") ||
            f.name.toUpperCase().includes("VERNIS")
        );

        const antifoil = otherColor.find((f) =>
          f.name.toUpperCase().includes("ANTIFOIL")
        );

        const foils = otherColor.filter((f) =>
          f.name.toUpperCase().includes("FOIL")
        );

        const opv = otherColor.find((f) =>
          f.name.toUpperCase().includes("OPV")
        );

        varnishFront = {
          percent: varnish?.p1 ?? 0,
          ml: varnish?.p1 ? getMlForEnnoblishment(0, varnish?.name) : 0,
        };

        foils.forEach((f) => {
          if (f.p1 && f.p1 > 0)
            foilFront.push({
              percent: f.p1 ?? 0,
              ml: f.p1 ? getMlForEnnoblishment(0, f.name) : 0,
            });

          if (f.p2 && f.p2 > 0)
            foilBack.push({
              percent: f.p2 ?? 0,
              ml: f.p2 ? getMlForEnnoblishment(0, f.name) : 0,
            });
        });

        opvFront = {
          percent: opv?.p1 ?? 0,
          ml: opv?.p1 ? getMlForEnnoblishment(0, opv?.name) : 0,
        };

        antifoilCoverage = {
          percent: antifoil?.p1 ?? 0,
          ml: antifoil?.p1 ? getMlForEnnoblishment(0, antifoil?.name) : 0,
        };

        varnishBack = {
          percent: varnish?.p2 ?? 0,
          ml: varnish?.p2 ? getMlForEnnoblishment(0, varnish?.name) : 0,
        };

        opvBack = {
          percent: opv?.p2 ?? 0,
          ml: opv?.p2 ? getMlForEnnoblishment(0, opv?.name) : 0,
        };

        if (job.pdfPreflightResponse.length > 1) {
          cmykBack = {
            cyan: {
              percent: round(
                job.pdfPreflightResponse[1].CMYKCoverage.Cyan * 0.6
              ),
              ml: getMlForInk(
                0,
                job.pdfPreflightResponse[1].CMYKCoverage.Cyan * 0.6
              ),
            },
            magenta: {
              percent: round(
                job.pdfPreflightResponse[1].CMYKCoverage.Magenta * 0.65
              ),
              ml: getMlForInk(
                0,
                job.pdfPreflightResponse[1].CMYKCoverage.Magenta * 0.65
              ),
            },
            yellow: {
              percent: round(
                job.pdfPreflightResponse[1].CMYKCoverage.Yellow * 0.63
              ),
              ml: getMlForInk(
                0,
                job.pdfPreflightResponse[1].CMYKCoverage.Yellow * 0.63
              ),
            },
            black: {
              percent: round(
                job.pdfPreflightResponse[1].CMYKCoverage.Black * 0.6
              ),
              ml: getMlForInk(
                0,
                job.pdfPreflightResponse[1].CMYKCoverage.Black * 0.6
              ),
            },
          };
        }
        setCoverage({
          front: {
            cmyk: {
              cyan: {
                percent: job.pdfPreflightResponse[0].CMYKCoverage.Cyan * 0.6,
                ml: getMlForInk(
                  0,
                  job.pdfPreflightResponse[0].CMYKCoverage.Cyan * 0.6
                ),
              },
              magenta: {
                percent:
                  job.pdfPreflightResponse[0].CMYKCoverage.Magenta * 0.65,
                ml: getMlForInk(
                  0,
                  job.pdfPreflightResponse[0].CMYKCoverage.Magenta * 0.65
                ),
              },
              yellow: {
                percent: job.pdfPreflightResponse[0].CMYKCoverage.Yellow * 0.63,
                ml: getMlForInk(
                  0,
                  job.pdfPreflightResponse[0].CMYKCoverage.Yellow * 0.63
                ),
              },
              black: {
                percent: job.pdfPreflightResponse[0].CMYKCoverage.Black * 0.6,
                ml: getMlForInk(
                  0,
                  job.pdfPreflightResponse[0].CMYKCoverage.Black * 0.6
                ),
              },
            },
            varnish: varnishFront,
            foil: foilFront,
            opv: opvFront,
            antifoil: antifoilCoverage,
          },
          back: {
            cmyk: cmykBack,
            varnish: varnishBack,
            foil: foilBack,
            opv: opvBack,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getMlForEnnoblishment,
      getMlForInk,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(job.pdfPreflightResponse),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(otherColor),
      setCoverage,
    ]
  );

  return job.pdfPreflightResponse ? (
    <>
      <div className={classNames(styles.result)}>
        {/* <div className={classNames(styles.jobnamezone)}>
          <div className={classNames(styles.jobname)}>
            <p>Job Name</p>
          </div>
          <div className={classNames(styles.job)}>
            <p>{job.name}</p>
          </div>
          <div className={classNames(styles.thumbnailszone)}>
            {job.pdfPreflightResponse?.map((p) => (
              <div className={classNames(styles.thumbnails)}>
                <p>Page {p.page} :</p>
                <img src={p.thumbnail} alt={`page ${p.page} thumbnail`} />
              </div>
            ))}
          </div>
        </div> */}

        <table className={classNames(styles.resultTable)}>
          <thead className={classNames(styles.headBg)}>
            <tr>
              <th
                style={{ width: machineType === "ALPHAJET" ? "auto" : "33.3%" }}
              >
                {t("coveragerate")}
              </th>
              {job.pdfPreflightResponse?.length === 1 && (
                <th
                  style={{
                    width: machineType === "ALPHAJET" ? "auto" : "66.6%",
                  }}
                >
                  {machineType === "COMPACT"
                    ? t("inkCons")
                    : t("inkConsumption", {
                        sheets: sheetNumber,
                        type:
                          machineType === "ALPHAJET" ? t("sheet") : t("label"),
                      })}
                </th>
              )}
              {job.pdfPreflightResponse?.map((p, i) => (
                <>
                  <th
                    className={classNames(
                      job.pdfPreflightResponse?.length === 1
                        ? styles.justrecto
                        : ""
                    )}
                  >
                    {job.pdfPreflightResponse?.length === 1
                      ? ""
                      : p.page === 1
                      ? t("front")
                      : t("back")}
                    {job.pdfPreflightResponse?.length &&
                      job.pdfPreflightResponse?.length > 1 && (
                        <p style={{ marginBottom: 0 }}>
                          {machineType === "COMPACT"
                            ? t("inkCons")
                            : t("inkConsumption", {
                                sheets: sheetNumber,
                                type:
                                  machineType === "ALPHAJET"
                                    ? t("sheet")
                                    : t("label"),
                              })}
                        </p>
                      )}
                  </th>
                </>
              ))}
            </tr>
          </thead>
          {machineType === "ALPHAJET" && (
            <tbody className={classNames(styles.tablecontent)}>
              <tr>
                <td className={classNames(styles.oneThird)}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      className={classNames(styles.leftcolor, styles.cyan)}
                    ></div>
                    <div className={classNames(styles.centerColor)}>Cyan</div>
                  </div>
                </td>
                {job.pdfPreflightResponse?.map((p, i) => (
                  <td
                    className={classNames(
                      job.pdfPreflightResponse?.length === 1
                        ? styles.onlyRecto
                        : styles.oneThird
                    )}
                  >
                    <div className={classNames(styles.half, styles.left)}>
                      {round(p.CMYKCoverage.Cyan * 0.6)} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(
                        getMlForInk(i, p.CMYKCoverage.Cyan * 0.6) * sheetNumber
                      )}{" "}
                      ml
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td className={classNames(styles.oneThird)}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      className={classNames(styles.leftcolor, styles.magenta)}
                    ></div>
                    <div className={classNames(styles.centerColor)}>
                      Magenta
                    </div>
                  </div>
                </td>
                {job.pdfPreflightResponse?.map((p, i) => (
                  <td
                    className={classNames(
                      job.pdfPreflightResponse?.length === 1
                        ? styles.onlyRecto
                        : styles.oneThird
                    )}
                  >
                    <div className={classNames(styles.half, styles.left)}>
                      {round(p.CMYKCoverage.Magenta * 0.65)} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(
                        getMlForInk(i, p.CMYKCoverage.Magenta * 0.65) *
                          sheetNumber
                      )}{" "}
                      ml
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td className={classNames(styles.oneThird)}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      className={classNames(styles.leftcolor, styles.yellow)}
                    ></div>
                    <div className={classNames(styles.centerColor)}>
                      {t("yellow")}
                    </div>
                  </div>
                </td>
                {job.pdfPreflightResponse?.map((p, i) => (
                  <td
                    className={classNames(
                      job.pdfPreflightResponse?.length === 1
                        ? styles.onlyRecto
                        : styles.oneThird
                    )}
                  >
                    <div className={classNames(styles.half, styles.left)}>
                      {round(p.CMYKCoverage.Yellow * 0.63)} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(
                        getMlForInk(i, p.CMYKCoverage.Yellow * 0.63) *
                          sheetNumber
                      )}{" "}
                      ml
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td className={classNames(styles.oneThird)}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      className={classNames(styles.leftcolor, styles.black)}
                    ></div>
                    <div className={classNames(styles.centerColor)}>
                      {t("black")}
                    </div>
                  </div>
                </td>
                {job.pdfPreflightResponse?.map((p, i) => (
                  <td
                    className={classNames(
                      job.pdfPreflightResponse?.length === 1
                        ? styles.onlyRecto
                        : styles.oneThird
                    )}
                  >
                    <div className={classNames(styles.half, styles.left)}>
                      {round(p.CMYKCoverage.Black * 0.6)} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(
                        getMlForInk(i, p.CMYKCoverage.Black * 0.6) * sheetNumber
                      )}{" "}
                      ml
                    </div>
                  </td>
                ))}
              </tr>
              {job.pdfPreflightResponse &&
              job.pdfPreflightResponse?.length < 2 ? (
                <tr>
                  <td
                    className={classNames(styles.oneThird, styles.importantBg)}
                  >
                    Total
                  </td>
                  <td className={classNames(styles.importantBg)}>
                    <div className={classNames(styles.half, styles.left)}>
                      {totalCMYKP1} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(conso.front.cmyk * 1000 * sheetNumber)} ml
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td
                    className={classNames(styles.oneThird, styles.importantBg)}
                  >
                    Total
                  </td>
                  <td className={classNames(styles.importantBg)}>
                    <div className={classNames(styles.half, styles.left)}>
                      {totalCMYKP1} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(conso.front.cmyk * 1000 * sheetNumber)} ml
                    </div>
                  </td>
                  <td className={classNames(styles.importantBg)}>
                    <div className={classNames(styles.half, styles.left)}>
                      {totalCMYKP2} %
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {round(conso.back.cmyk * 1000 * sheetNumber)} ml
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>

        {otherColor &&
          otherColor.length > 0 &&
          otherColor.map((e, i) => (
            <table className={classNames(styles.foilTable)}>
              <tbody>
                <tr>
                  <td
                    className={classNames(styles.oneThird, styles.importantBg)}
                  >
                    {t(
                      e.name === "Vernis"
                        ? machineType === "ALPHAJET"
                          ? "Vernis"
                          : "clearVarnish"
                        : e.name
                    )}
                  </td>
                  <td
                    className={classNames(
                      job.pdfPreflightResponse?.length === 1
                        ? styles.onlyRecto
                        : styles.oneThird,
                      styles.importantBg
                    )}
                  >
                    <div className={classNames(styles.half, styles.left)}>
                      {e.p1 ? round(e.p1) + " %" : "-"}
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {e.p1
                        ? round(
                            getMlForEnnoblishment(0, e.name) * sheetNumber
                          ) + " ml"
                        : "-"}
                    </div>
                  </td>
                  {job.pdfPreflightResponse?.length === 1 ? (
                    ""
                  ) : (
                    <td
                      className={classNames(
                        styles.oneThird,
                        styles.importantBg
                      )}
                    >
                      <div className={classNames(styles.half, styles.left)}>
                        {e.p2 ? round(e.p2) + " %" : "-"}
                      </div>
                      <div className={classNames(styles.half, styles.right)}>
                        {e.p2
                          ? round(
                              getMlForEnnoblishment(1, e.name) * sheetNumber
                            ) + " ml"
                          : "-"}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          ))}
        {hasOpv ? (
          <table className={classNames(styles.foilTable)}>
            <tbody>
              <tr>
                <td className={classNames(styles.oneThird, styles.importantBg)}>
                  OPV
                </td>
                <td
                  className={classNames(
                    job.pdfPreflightResponse?.length === 1
                      ? styles.onlyRecto
                      : styles.oneThird,
                    styles.importantBg
                  )}
                >
                  <div className={classNames(styles.half, styles.left)}>
                    {opvA
                      ? (opvType === "file"
                          ? opvPercent?.[0].percent
                          : opvType === "spot"
                          ? totalCMYKP1
                          : "100") + " %"
                      : "-"}
                  </div>
                  <div className={classNames(styles.half, styles.right)}>
                    {opvA
                      ? round(conso.front.opv * 1000 * sheetNumber) + " ml"
                      : "-"}
                  </div>
                </td>
                {job.pdfPreflightResponse?.length === 1 ? (
                  ""
                ) : (
                  <td
                    className={classNames(styles.oneThird, styles.importantBg)}
                  >
                    <div className={classNames(styles.half, styles.left)}>
                      {opvB
                        ? (opvType === "file"
                            ? opvPercent?.[1].percent
                            : opvType === "spot"
                            ? totalCMYKP2
                            : "100") + " %"
                        : "-"}
                    </div>
                    <div className={classNames(styles.half, styles.right)}>
                      {opvB
                        ? round(conso.back.opv * 1000 * sheetNumber) + " ml"
                        : "-"}
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    </>
  ) : null;
};
export default Result;
