import { PdfPreflight } from "../pdfPreflight.interface";
import React, { useEffect, useMemo } from "react";
//import Chartgoogle from "react-google-charts";
import {
  calculateInkFoilVarnishPercentCover,
  formatNumber,
} from "./calculator/calculator";
import roiResult, { ConsoPerFace, RoiResult } from "./calculator/roiResult";
import {
  OptimizedFoilUsagePerSheet,
  RoiParams,
  StaticValueOfParameters,
} from "./calculator/staticInput";
import classNames from "classnames";
import styles from "./style/dashboard.module.scss";
import { useTranslation } from "react-i18next";
import { CostsType, Devise, OpvType } from "../calculatorTypes";
import TableFoilCost from "./TableFoilCost";
import { useParams } from "react-router-dom";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";
import TableFoilCostCompact from "./TableFoilCostCompact";

const CostCalculator = ({
  job,
  varnishA,
  antifoil,
  foilA,
  opvA,
  varnishB,
  foilB,
  opvB,
  inkCost,
  varnishCost,
  antifoilCost,
  foilCost,
  foilRollSize,
  paperCost,
  primerCost,
  finalHeight,
  finalWidth,
  upsPerSheet,
  sheetNumber,
  setCosts,
  hasFoil,
  hasVarnish,
  hasAntifoil,
  hasCMYK,
  hasOpv,
  devise,
  setConso,
  foilInfoA,
  foilInfoB,
  opvType,
  opvA2d3d,
  opvB2d3d,
  foilList,
  doubleSided,
  setFoilSurface,
  compactVarnishAssociation,
  visuSize,
  sqmPrice,
}: {
  job: PdfPreflight;
  varnishA: number;
  antifoil: number;
  foilA: number;
  opvA: boolean;
  varnishB: number;
  foilB: number;
  opvB: boolean;
  inkCost: number;
  varnishCost: number;
  antifoilCost: number;
  foilCost: number;
  foilRollSize: number;
  paperCost: number;
  primerCost: number;
  finalHeight: number;
  finalWidth: number;
  upsPerSheet: number;
  sheetNumber: number;
  setCosts: React.Dispatch<CostsType>;
  costs: CostsType;
  hasVarnish: boolean | undefined;
  hasAntifoil: boolean | undefined;
  hasFoil: boolean | undefined;
  hasCMYK: boolean | undefined;
  hasOpv: boolean | undefined;
  devise: Devise;
  setConso: React.Dispatch<ConsoPerFace>;
  foilInfoA: OptimizedFoilUsagePerSheet;
  foilInfoB: OptimizedFoilUsagePerSheet;
  opvType: OpvType;
  opvA2d3d: number;
  opvB2d3d: number;
  foilList: string[];
  doubleSided: boolean;
  setFoilSurface: React.Dispatch<number | undefined>;
  compactVarnishAssociation:
    | {
        varnish: string | undefined;
        antifoil: string | undefined;
      }
    | undefined;
  visuSize: { length: number; width: number };
  sqmPrice: number;
}) => {
  const inkFoilPercentColor = useMemo(
    () => calculateInkFoilVarnishPercentCover(job, foilList),
    [foilList, job]
  );

  const { machineType } = useParams<{
    machineType: MachineModelType | undefined;
  }>();

  const roiCostPrint: RoiResult = useMemo(() => {
    const roiParams: RoiParams = {
      sqmFront: undefined,
      sqmBack: undefined,
      numberOfSheets: StaticValueOfParameters.numberOfSheets,
      paperCostForJob: paperCost ?? StaticValueOfParameters.paperCostForJob,
      primerCost: primerCost ?? StaticValueOfParameters.primerCost,
      isInternalEcoConso: StaticValueOfParameters.isInternalEcoConso,
      paperFormat: {
        length: Math.round(
          finalHeight ??
            job.pdfPreflightResponse?.[0].PdfSize.height ??
            StaticValueOfParameters.paperFormat.length
        ),
        width: Math.round(
          finalWidth ??
            job.pdfPreflightResponse?.[0].PdfSize.width ??
            StaticValueOfParameters.paperFormat.width
        ),
      },
      imageFormat: visuSize,
      alphaJetGlobalPrintingCosts: {
        costPerInkLiter:
          inkCost ??
          StaticValueOfParameters.alphaJetGlobalPrintingCosts.costPerInkLiter,
        costPerUvVarnishLiter:
          varnishCost ??
          StaticValueOfParameters.alphaJetGlobalPrintingCosts
            .costPerUvVarnishLiter,
        costPerLiterB1:
          StaticValueOfParameters.alphaJetGlobalPrintingCosts.costPerLiterB1,
        costPerAntifoilLiter:
          antifoilCost ??
          StaticValueOfParameters.alphaJetGlobalPrintingCosts
            .costPerAntifoilLiter,
      },
      front: {
        embellishmentUv2d3dEffect:
          varnishA ?? StaticValueOfParameters.front.embellishmentUv2d3dEffect,
        digitalHotFoil2d3dEffect:
          foilA ?? StaticValueOfParameters.front.digitalHotFoil2d3dEffect,
        opv2d3d: opvA2d3d ?? StaticValueOfParameters.front.opv2d3d,
        opv: opvA ?? StaticValueOfParameters.front.opv,
        optimizedFoilUsagePerSheet: {
          maximizedFoilWidthUsageRollWidth:
            foilInfoA.maximizedFoilWidthUsageRollWidth ?? finalWidth,
          maximizedFoilLengthUsagePerSheet:
            foilInfoA.maximizedFoilLengthUsagePerSheet ?? finalHeight,
          TotalFoilLengthUsagePerSheet:
            foilInfoA.TotalFoilLengthUsagePerSheet ??
            finalHeight +
              StaticValueOfParameters.front.optimizedFoilUsagePerSheet
                .FoilMasterPerRollUpDown,
          FoilMasterPerRollUpDown:
            StaticValueOfParameters.front.optimizedFoilUsagePerSheet
              .FoilMasterPerRollUpDown,
        },
        antifoil2d3dEffect:
          antifoil ?? StaticValueOfParameters.front.antifoil2d3dEffect,
      },
      back: {
        embellishmentUv2d3dEffect:
          varnishB ?? StaticValueOfParameters.back.embellishmentUv2d3dEffect,
        digitalHotFoil2d3dEffect:
          foilB ?? StaticValueOfParameters.back.digitalHotFoil2d3dEffect,
        opv2d3d: opvB2d3d ?? StaticValueOfParameters.front.opv2d3d,
        opv: opvB ?? StaticValueOfParameters.back.opv,
        optimizedFoilUsagePerSheet: {
          maximizedFoilWidthUsageRollWidth:
            foilInfoB.maximizedFoilWidthUsageRollWidth ?? finalWidth,
          maximizedFoilLengthUsagePerSheet:
            foilInfoB.maximizedFoilLengthUsagePerSheet ?? finalHeight,
          TotalFoilLengthUsagePerSheet:
            foilInfoB.TotalFoilLengthUsagePerSheet ??
            finalHeight +
              StaticValueOfParameters.back.optimizedFoilUsagePerSheet
                .FoilMasterPerRollUpDown,
          FoilMasterPerRollUpDown:
            StaticValueOfParameters.back.optimizedFoilUsagePerSheet
              .FoilMasterPerRollUpDown,
        },
      },
      foilInformation: StaticValueOfParameters.foilInformation,
      isSqm: StaticValueOfParameters.isSqm,
      sqmPrice: foilCost ?? StaticValueOfParameters.sqmPrice,
      foilRollSize: foilRollSize ?? StaticValueOfParameters.foilRollSize,
    };
    const result = roiResult(
      inkFoilPercentColor,
      roiParams,
      upsPerSheet,
      sheetNumber,
      finalHeight,
      finalWidth,
      opvType,
      foilList,
      job.pdfPreflightResponse,
      machineType,
      compactVarnishAssociation
    );

    const totalSurface =
      (result.sqmFront?.reduce((a, b) => a + (b?.sqmTotal ?? 0), 0) ?? 0) +
      (result.sqmBack?.reduce((a, b) => a + (b?.sqmTotal ?? 0), 0) ?? 0);
    setFoilSurface(totalSurface);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paperCost,
    primerCost,
    finalHeight,
    job.pdfPreflightResponse,
    finalWidth,
    inkCost,
    varnishCost,
    antifoilCost,
    varnishA,
    foilA,
    opvA2d3d,
    opvA,
    foilInfoA.maximizedFoilWidthUsageRollWidth,
    foilInfoA.maximizedFoilLengthUsagePerSheet,
    foilInfoA.TotalFoilLengthUsagePerSheet,
    antifoil,
    varnishB,
    foilB,
    opvB2d3d,
    opvB,
    foilInfoB.maximizedFoilWidthUsageRollWidth,
    foilInfoB.maximizedFoilLengthUsagePerSheet,
    foilInfoB.TotalFoilLengthUsagePerSheet,
    foilCost,
    foilRollSize,
    inkFoilPercentColor,
    upsPerSheet,
    sheetNumber,
    opvType,
    foilList,
    machineType,
    compactVarnishAssociation,
    setFoilSurface,
  ]);

  useEffect(() => {
    setCosts({
      visu: {
        costOfMaterial: roiCostPrint.costPerVisu.costOfMaterial,
        costofCMYKPrint: roiCostPrint.costPerVisu.costofCMYKPrint,
        costofVarnishUsed: roiCostPrint.costPerVisu.costofVarnishUsed,
        costofAntifoilUsed: roiCostPrint.costPerVisu.costofAntifoilUsed,
        finalOpvCost: roiCostPrint.costPerVisu.finalOpvCost,
        foilCosts: roiCostPrint.costPerVisu.foilCosts,
        totalCostofJob: roiCostPrint.costPerVisu.totalCostofJob,
      },
      page: {
        costOfMaterial: roiCostPrint.costPerPage.costOfMaterial,
        costofCMYKPrint: roiCostPrint.costPerPage.costofCMYKPrint,
        costofVarnishUsed: roiCostPrint.costPerPage.costofVarnishUsed,
        costofAntifoilUsed: roiCostPrint.costPerPage.costofAntifoilUsed,
        finalOpvCost: roiCostPrint.costPerPage.finalOpvCost,
        foilCosts: roiCostPrint.costPerPage.foilCosts,
        totalCostofJob: roiCostPrint.costPerPage.totalCostofJob,
      },
      total: {
        costOfMaterial: roiCostPrint.totalCost.costOfMaterial,
        costofCMYKPrint: roiCostPrint.totalCost.costofCMYKPrint,
        costofVarnishUsed: roiCostPrint.totalCost.costofVarnishUsed,
        costofAntifoilUsed: roiCostPrint.totalCost.costofAntifoilUsed,
        finalOpvCost: roiCostPrint.totalCost.finalOpvCost,
        foilCosts: roiCostPrint.totalCost.foilCosts,
        totalCostofJob: roiCostPrint.totalCost.totalCostofJob,
      },
      sqmPrice: sqmPrice,
    });

    setConso({
      front: {
        cmyk: roiCostPrint.conso.front.cmyk,
        varnish: roiCostPrint.conso.front.varnish,
        foil: roiCostPrint.conso.front.foil ?? [],
        opv: roiCostPrint.conso.front.opv,
        antifoil: roiCostPrint.conso.front.antifoil,
      },
      back: {
        cmyk: roiCostPrint.conso.back.cmyk,
        varnish: roiCostPrint.conso.back.varnish,
        foil: roiCostPrint.conso.back.foil ?? [],
        opv: roiCostPrint.conso.back.opv,
      },
    });
  }, [
    sqmPrice,
    setCosts,
    roiCostPrint.conso.front.cmyk,
    roiCostPrint.conso.front.varnish,
    roiCostPrint.conso.back.cmyk,
    roiCostPrint.conso.back.varnish,
    setConso,
    roiCostPrint.costPerVisu.costOfMaterial,
    roiCostPrint.costPerVisu.costofCMYKPrint,
    roiCostPrint.costPerVisu.costofVarnishUsed,
    roiCostPrint.costPerVisu.finalOpvCost,
    roiCostPrint.costPerVisu.totalCostofJob,
    roiCostPrint.costPerPage.costOfMaterial,
    roiCostPrint.costPerPage.costofCMYKPrint,
    roiCostPrint.costPerPage.costofVarnishUsed,
    roiCostPrint.costPerPage.finalOpvCost,
    roiCostPrint.costPerPage.totalCostofJob,
    roiCostPrint.totalCost.costOfMaterial,
    roiCostPrint.totalCost.costofCMYKPrint,
    roiCostPrint.totalCost.costofVarnishUsed,
    roiCostPrint.totalCost.finalOpvCost,
    roiCostPrint.totalCost.totalCostofJob,
    roiCostPrint.conso.front.opv,
    roiCostPrint.conso.back.opv,
    roiCostPrint.costPerVisu.costofAntifoilUsed,
    roiCostPrint.costPerVisu.foilCosts,
    roiCostPrint.costPerPage.costofAntifoilUsed,
    roiCostPrint.costPerPage.foilCosts,
    roiCostPrint.totalCost.costofAntifoilUsed,
    roiCostPrint.totalCost.foilCosts,
    roiCostPrint.conso.front.foil,
    roiCostPrint.conso.front.antifoil,
    roiCostPrint.conso.back.foil,
  ]);
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames(styles.dashboardzone)}>
        <div className={classNames(styles.dispflex, styles.dashtext)}>
          <div className={classNames(styles.dashtextzone)}>
            {/* <div className={classNames(styles.heightalign, "col-10")}>
            <p className={classNames(styles.fontW600, styles.heightalign)}>
              PER SHEET DETAILED BREAKDOWN
            </p>
          </div> */}
            <table className={classNames(styles.resultTable)}>
              <thead className={classNames(styles.headBg)}>
                <tr>
                  <th className={classNames(styles.thFontSize)}>{t("cout")}</th>
                  <th className={classNames(styles.thFontSize)}>
                    {t(machineType === "ALPHAJET" ? "Visuel" : "items")}
                  </th>
                  <th className={classNames(styles.thFontSize)}>
                    {t(machineType === "ALPHAJET" ? "Feuille" : "image")}
                  </th>
                  <th className={classNames(styles.thFontSize)}>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("Substrate")}</td>
                  <td>
                    {formatNumber(roiCostPrint.costPerVisu.costOfMaterial, 6)}{" "}
                    {devise}
                  </td>
                  <td>
                    {formatNumber(roiCostPrint.costPerPage.costOfMaterial, 6)}{" "}
                    {devise}
                  </td>
                  <td>
                    {formatNumber(roiCostPrint.totalCost.costOfMaterial, 6)}{" "}
                    {devise}
                  </td>
                </tr>
                {hasCMYK && machineType === "ALPHAJET" && (
                  <tr>
                    <td>{t("CMYK")}</td>
                    <td>
                      {formatNumber(
                        roiCostPrint.costPerVisu.costofCMYKPrint,
                        6
                      )}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(
                        roiCostPrint.costPerPage.costofCMYKPrint,
                        6
                      )}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(roiCostPrint.totalCost.costofCMYKPrint, 6)}{" "}
                      {devise}
                    </td>
                  </tr>
                )}
                {machineType === "ALPHAJET" && (
                  <tr>
                    <td>{t("Primer")}</td>
                    <td>
                      {formatNumber(roiCostPrint.costPerVisu.costofPrimer, 6)}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(roiCostPrint.costPerPage.costofPrimer, 6)}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(roiCostPrint.totalCost.costofPrimer, 6)}{" "}
                      {devise}
                    </td>
                  </tr>
                )}
                {hasVarnish && machineType === "ALPHAJET" && (
                  <tr>
                    <td>
                      {t(
                        machineType === "ALPHAJET" ? "spotUv" : "clearVarnish"
                      )}
                    </td>
                    <td>
                      {formatNumber(
                        roiCostPrint.costPerVisu.costofVarnishUsed,
                        6
                      )}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(
                        roiCostPrint.costPerPage.costofVarnishUsed,
                        6
                      )}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(
                        roiCostPrint.totalCost.costofVarnishUsed,
                        6
                      )}{" "}
                      {devise}
                    </td>
                  </tr>
                )}

                {hasAntifoil && compactVarnishAssociation?.antifoil && (
                  <tr>
                    <td>{t("Antifoil")}</td>
                    <td>
                      {formatNumber(
                        roiCostPrint.costPerVisu.costofAntifoilUsed ?? 0,
                        6
                      )}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(
                        roiCostPrint.costPerPage.costofAntifoilUsed ?? 0,
                        6
                      )}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(
                        roiCostPrint.totalCost.costofAntifoilUsed ?? 0,
                        6
                      )}{" "}
                      {devise}
                    </td>
                  </tr>
                )}
                {hasFoil &&
                  (machineType === "ALPHAJET" ||
                    compactVarnishAssociation?.varnish) &&
                  foilList.map((f) =>
                    machineType === "ALPHAJET" ? (
                      <TableFoilCost
                        devise={devise}
                        foil={f}
                        roiCostPrint={roiCostPrint}
                        preflight={job.pdfPreflightResponse}
                        machineType={machineType}
                      />
                    ) : (
                      <TableFoilCostCompact
                        devise={devise}
                        foil={f}
                        roiCostPrint={roiCostPrint}
                        preflight={job.pdfPreflightResponse}
                        machineType={machineType}
                      />
                    )
                  )}
                {hasOpv ? (
                  <tr>
                    <td>OPV</td>
                    <td>
                      {formatNumber(roiCostPrint.costPerVisu.finalOpvCost, 6)}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(roiCostPrint.costPerPage.finalOpvCost, 6)}{" "}
                      {devise}
                    </td>
                    <td>
                      {formatNumber(roiCostPrint.totalCost.finalOpvCost, 6)}{" "}
                      {devise}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {hasFoil && machineType === "ALPHAJET" && (
        <div className={classNames(styles.dashboardzone)}>
          <div className={classNames(styles.dispflex, styles.dashtext)}>
            <div className={classNames(styles.dashtextzone)}>
              {/* <div className={classNames(styles.heightalign, "col-10")}>
            <p className={classNames(styles.fontW600, styles.heightalign)}>
              PER SHEET DETAILED BREAKDOWN
            </p>
          </div> */}
              <table className={classNames(styles.resultTable)}>
                <thead className={classNames(styles.headBg)}>
                  <tr>
                    <th
                      className={classNames(styles.headBglittlefont)}
                      style={{ width: "10%" }}
                    >
                      {t("side")}
                    </th>
                    <th style={{ width: "90%" }}>
                      <tr style={{ width: "100%", display: "flex" }}>
                        <th className={classNames(styles.innerTh)}>
                          {t("Name")}
                        </th>
                        <th
                          className={classNames(
                            styles.innerTh,
                            styles.subTableInnerTh
                          )}
                        >
                          {t("surface")}
                        </th>
                        <th className={classNames(styles.innerThSmall)}>
                          {t("surfaceTotal")}
                        </th>
                      </tr>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "10%" }}>{t("front")}</td>
                    <td style={{ padding: 0 }}>
                      {roiCostPrint.sqmFront?.map((s) => (
                        <tr
                          className={classNames(styles.innerTr)}
                          style={{ display: "flex" }}
                        >
                          <td
                            className={classNames(
                              styles.innerTd,
                              styles.flexTd,
                              styles.borderBot
                            )}
                          >
                            {s.name}
                          </td>
                          <table
                            className={classNames(
                              styles.innerTd,
                              styles.subTable
                            )}
                            style={{
                              height:
                                roiCostPrint.sqmFront?.length === 1
                                  ? "120px"
                                  : "100%",
                            }}
                          >
                            <thead className={classNames(styles.headBg)}>
                              <tr className={classNames(styles.height100)}>
                                <th style={{ width: "100%" }}>
                                  <tr
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      display: "flex",
                                    }}
                                    // className={classNames(styles.height100)}
                                  >
                                    <th
                                      className={classNames(
                                        styles.subInnerTh,
                                        roiCostPrint.sqmFront?.length === 1 &&
                                          styles.height100
                                      )}
                                    >
                                      {t("N°")}
                                    </th>
                                    <th
                                      className={classNames(
                                        styles.subInnerTh,
                                        roiCostPrint.sqmFront?.length === 1 &&
                                          styles.height100
                                      )}
                                    >
                                      {t("Width")}
                                    </th>
                                    <th
                                      className={classNames(
                                        styles.subInnerTh,
                                        roiCostPrint.sqmFront?.length === 1 &&
                                          styles.height100
                                      )}
                                    >
                                      {t("Height")}
                                    </th>
                                    <th
                                      className={classNames(
                                        styles.subInnerTh,
                                        roiCostPrint.sqmFront?.length === 1 &&
                                          styles.height100,
                                        styles.noborderright
                                      )}
                                    >
                                      {t("surface")}
                                    </th>
                                  </tr>
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ height: "100%" }}>
                              {s.foilSurfaces?.map((fs, i) => (
                                <tr
                                  className={classNames(
                                    styles.tablecontentheight100
                                  )}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <td
                                    className={classNames(
                                      styles.subInnerTd,
                                      s.foilSurfaces?.length === 1
                                        ? styles.height100
                                        : styles.height40
                                    )}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    className={classNames(
                                      styles.subInnerTd,
                                      s.foilSurfaces?.length === 1
                                        ? styles.height100
                                        : styles.height40
                                    )}
                                  >
                                    {round(fs.width)}
                                  </td>
                                  <td
                                    className={classNames(
                                      styles.subInnerTd,
                                      s.foilSurfaces?.length === 1
                                        ? styles.height100
                                        : styles.height40
                                    )}
                                  >
                                    {round(fs.height)}
                                  </td>
                                  <td
                                    className={classNames(
                                      styles.subInnerTd,
                                      s.foilSurfaces?.length === 1
                                        ? styles.height100noborderright
                                        : styles.height40noborderright
                                    )}
                                  >
                                    {round(fs.cover / 1000000)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <td
                            className={classNames(
                              styles.borderBot,
                              styles.innerTd,
                              styles.flexTd
                            )}
                          >
                            {round(s.sqmTotal ?? 0)}
                          </td>
                        </tr>
                      ))}
                    </td>
                  </tr>
                  {doubleSided && (
                    <tr>
                      <td style={{ width: "10%" }}>{t("back")}</td>
                      <td style={{ padding: 0, borderTop: "none !important" }}>
                        {roiCostPrint.sqmBack?.map((s) => (
                          <tr
                            className={classNames(styles.innerTr)}
                            style={{ display: "flex" }}
                          >
                            <td
                              className={classNames(
                                styles.innerTd,
                                styles.flexTd
                              )}
                            >
                              {s.name}
                            </td>
                            <table
                              className={classNames(
                                styles.innerTd,
                                styles.subTable,
                                styles.bordertop,
                                styles.resultTable
                              )}
                              style={{
                                height: "120px",
                              }}
                            >
                              <thead className={classNames(styles.headBg)}>
                                <tr className={classNames(styles.height100)}>
                                  <th style={{ width: "100%" }}>
                                    <tr
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                      }}
                                    >
                                      <th
                                        className={classNames(
                                          styles.subInnerTh,
                                          roiCostPrint.sqmBack?.length === 1 &&
                                            styles.height100
                                        )}
                                      >
                                        {t("N°")}
                                      </th>
                                      <th
                                        className={classNames(
                                          styles.subInnerTh,
                                          roiCostPrint.sqmBack?.length === 1 &&
                                            styles.height100
                                        )}
                                      >
                                        {t("Width")}
                                      </th>
                                      <th
                                        className={classNames(
                                          styles.subInnerTh,
                                          roiCostPrint.sqmBack?.length === 1 &&
                                            styles.height100
                                        )}
                                      >
                                        {t("Height")}
                                      </th>
                                      <th
                                        className={classNames(
                                          styles.subInnerTh,
                                          roiCostPrint.sqmBack?.length === 1 &&
                                            styles.height100,
                                          styles.noborderright
                                        )}
                                      >
                                        {t("surface")}
                                      </th>
                                    </tr>
                                  </th>
                                </tr>
                              </thead>
                              <tbody style={{ height: "100%" }}>
                                {s.foilSurfaces?.map((fs, i) => (
                                  <tr
                                    className={classNames(
                                      styles.tablecontentheight100
                                    )}
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      height: "100%",
                                    }}
                                  >
                                    <td
                                      className={classNames(
                                        styles.subInnerTd,
                                        s.foilSurfaces?.length === 1
                                          ? styles.height100
                                          : styles.height40
                                      )}
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      className={classNames(
                                        styles.subInnerTd,
                                        s.foilSurfaces?.length === 1
                                          ? styles.height100
                                          : styles.height40
                                      )}
                                    >
                                      {round(fs.width)}
                                    </td>
                                    <td
                                      className={classNames(
                                        styles.subInnerTd,
                                        s.foilSurfaces?.length === 1
                                          ? styles.height100
                                          : styles.height40
                                      )}
                                    >
                                      {round(fs.height)}
                                    </td>
                                    <td
                                      className={classNames(
                                        styles.subInnerTd,
                                        s.foilSurfaces?.length === 1
                                          ? styles.height100noborderright
                                          : styles.height40noborderright
                                      )}
                                    >
                                      {round(fs.cover / 1000000)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <td
                              className={classNames(
                                styles.innerTd,
                                styles.flexTd
                              )}
                            >
                              {round(s.sqmTotal ?? 0)}
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function round(num: number) {
  return Math.round(num * 10000) / 10000;
}

export default CostCalculator;
