import { PdfPreflight } from "../../pdfPreflight.interface";
import { FaceCoverage } from "./roiResult";

export interface resultPerSheetPrice {
  cmyk: number;
  foil: number;
  varnish: number;
  substrate: number;
}
export function formatNumber(num: number, fixed = 2) {
  const round = Math.pow(10, fixed);
  const value = Math.round(num * round) / round;
  return !isNaN(value) ? value : "-";
}

export function calculateInkFoilVarnishPercentCover(
  job: PdfPreflight,
  foilList: string[]
): FaceCoverage[] {
  const pdfReponse: FaceCoverage[] | undefined = job.pdfPreflightResponse?.map(
    (m) => {
      return {
        page: m.page - 1,
        cmykPercent:
          m.CMYKCoverage.Cyan * 0.6 +
          m.CMYKCoverage.Magenta * 0.65 +
          m.CMYKCoverage.Yellow * 0.63 +
          m.CMYKCoverage.Black * 0.6,
        foilPercent: m.otherColor
          .filter((f) =>
            foilList
              .map((f) => f.toUpperCase())
              .map((f) => f.toUpperCase())
              .includes(f.name.toUpperCase())
          )
          .map((f) => {
            return { name: f.name, value: f.percent };
          }),
        varnishPercent:
          m.otherColor.find(
            (f) =>
              f.name.toUpperCase().includes("VARNISH") ||
              f.name.toUpperCase().includes("VERNIS")
          )?.percent ?? 0,
        opvPercent:
          m.otherColor.find((f) => f.name.toUpperCase().includes("OPV"))
            ?.percent ?? 0,
        antifoilPercent:
          m.otherColor.find((f) => f.name.toUpperCase().includes("ANTIFOIL"))
            ?.percent ?? 0,
      };
    }
  );
  return pdfReponse ? pdfReponse : [];
}
