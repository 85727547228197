import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";

import { MissingOrgError, UserProfile } from "@mgi-labs/mgi-id";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

export async function testPdf(
  lang: string,
  profile: UserProfile | typeof MissingOrgError | undefined,
  calculatorData: any,
  machineType: MachineModelType
) {
  const url = getPdf(lang, machineType);

  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  function round(num: number) {
    return Math.round(num * 1000) / 1000;
  }

  const pages = pdfDoc.getPages();

  const firstPage = pages[0];

  const name =
    typeof profile === "object"
      ? `${profile?.firstName} ${profile?.lastName}`
      : "";

  const imageRecto =
    calculatorData.pdfPreflight.pdfPreflightResponse[0].thumbnail;
  const imageVerso = calculatorData.pdfPreflight.pdfPreflightResponse[1]
    ? calculatorData.pdfPreflight.pdfPreflightResponse[1].thumbnail
    : "";

  const imageRectoBytes =
    imageRecto && (await fetch(imageRecto).then((res) => res.arrayBuffer()));

  const imageVersoBytes =
    imageVerso && (await fetch(imageVerso).then((res) => res.arrayBuffer()));
  const finalImageRecto =
    imageRecto && (await pdfDoc.embedJpg(imageRectoBytes));
  const finalImageVerso =
    imageVerso && (await pdfDoc.embedJpg(imageVersoBytes));
  const imageRectoDims = finalImageRecto && finalImageRecto.scale(1);
  const imageVersoDims = finalImageVerso && finalImageVerso.scale(1);

  const totalCMYKPConsoFront = round(
    calculatorData.coverage.front.cmyk.cyan.ml +
      calculatorData.coverage.front.cmyk.magenta.ml +
      calculatorData.coverage.front.cmyk.yellow.ml +
      calculatorData.coverage.front.cmyk.black.ml
  );

  const totalCMYKPPercentFront = round(
    calculatorData.coverage.front.cmyk.cyan.percent +
      calculatorData.coverage.front.cmyk.magenta.percent +
      calculatorData.coverage.front.cmyk.yellow.percent +
      calculatorData.coverage.front.cmyk.black.percent
  );

  const totalCMYKPConsoBack = round(
    calculatorData.coverage.back.cmyk.cyan.ml +
      calculatorData.coverage.back.cmyk.magenta.ml +
      calculatorData.coverage.back.cmyk.yellow.ml +
      calculatorData.coverage.back.cmyk.black.ml
  );

  const totalCMYKPPercentBack = round(
    calculatorData.coverage.back.cmyk.cyan.percent +
      calculatorData.coverage.back.cmyk.magenta.percent +
      calculatorData.coverage.back.cmyk.yellow.percent +
      calculatorData.coverage.back.cmyk.black.percent
  );

  //GENERAL INFOS

  if (calculatorData.name.length > 56) {
    const newString1 = calculatorData.name.slice(0, 55);
    const newString2 = calculatorData.name.slice(
      56,
      calculatorData.name.length
    );

    firstPage.drawText(newString1 ?? "File Name", {
      x: 300,
      y: 3147,
      size: 28,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(newString2 ?? "File Name", {
      x: 300,
      y: 3110,
      size: 28,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  } else {
    firstPage.drawText(calculatorData.name ?? "File Name", {
      x: 300,
      y: 3126,
      size: 28,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  }

  firstPage.drawText(name ?? "Name", {
    x: 300,
    y: 3226,
    size: 28,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(
    machineType.toUpperCase() === "COMPACT"
      ? "JETVARNISH 3D WEB400"
      : machineType.toUpperCase() ?? "ALPHAJET",
    {
      x: machineType === "ALPHAJET" ? 2140 : 2050,
      y: 3374,
      size: 28,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  firstPage.drawText(
    new Date(calculatorData.dates.modified).toLocaleDateString() ??
      new Date().toLocaleDateString() ??
      "Name",
    {
      x: 2140,
      y: 3226,
      size: 28,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  firstPage.drawText(
    new Date(calculatorData.dates.created).toLocaleDateString() ??
      new Date().toLocaleDateString() ??
      "Name",
    {
      x: 2140,
      y: 3126,
      size: 28,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  //IMAGES
  finalImageRecto &&
    imageRectoDims &&
    firstPage.drawImage(finalImageRecto, {
      x:
        calculatorData.pdfPreflight.pdfPreflightResponse.length < 2 ? 400 : 200,
      y: 2482,
      width: imageRectoDims.width,
      height: imageRectoDims.height,
    });

  finalImageVerso &&
    imageRectoDims &&
    firstPage.drawImage(finalImageVerso, {
      x: 700,
      y: 2482,
      width: imageVersoDims.width,
      height: imageVersoDims.height,
    });

  //OUTPUT
  firstPage.drawText(
    calculatorData.output.size.width.toString() + " mm" ?? "1020",
    {
      x: 2160,
      y: 2878,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  firstPage.drawText(
    calculatorData.output.size.height.toString() + " mm" ?? "720",
    {
      x: 2160,
      y: 2778,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  firstPage.drawText(calculatorData.output.upsPerSheet.toString() ?? "-", {
    x: 2205,
    y: 2678,
    size: 27,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(calculatorData.output.copyNumber.toString() ?? "-", {
    x: 2205,
    y: 2578,
    size: 27,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("1" ?? "-", {
    x: 2205,
    y: 2478,
    size: 27,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawRectangle({
    width: 2000,
    height: 101,
    x: 1250,
    y: 2435,
    color: rgb(1, 1, 1),
  });

  //DEVISE
  firstPage.drawText(calculatorData.costs.devise ?? "-", {
    x: 2290,
    y: 2329,
    size: 27,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  //VISU SIZE
  firstPage.drawText(
    calculatorData.visuSize.width.toString() +
      " x " +
      calculatorData.visuSize.height.toString() +
      " mm" ?? "-",
    {
      x: 975,
      y: 2323,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  //RECTO INFO
  firstPage.drawText(
    (machineType === "ALPHAJET"
      ? calculatorData.infos.front.varnish.toString()
      : calculatorData.infos.front.antifoil.toString()) + " µm" ?? "-",
    {
      x: machineType === "ALPHAJET" ? 925 : 1090,
      y: machineType === "ALPHAJET" ? 1979 : 2079,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  firstPage.drawText(
    calculatorData.infos.front.foil.toString() + " µm" ?? "-",
    {
      x: machineType === "ALPHAJET" ? 925 : 1090,
      y: machineType === "ALPHAJET" ? 1879 : 1979,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    }
  );

  machineType === "ALPHAJET" &&
    firstPage.drawText(
      `(${lang === "fr" ? "Vernis de protection" : "Protective Varnish"}) :`,
      {
        x: 200,
        y: 1775,
        size: 30,
        font: helveticaFont,
        color: rgb(0.44, 0.44, 0.44),
      }
    );

  machineType === "ALPHAJET" &&
    firstPage.drawText(
      dashIfZero(
        calculatorData.infos.front.opv ? round(totalCMYKPPercentFront) : 0,
        "%"
      ) ?? "-",
      {
        x: 905,
        y: 1779,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

  //VERSO INFO
  if (machineType === "ALPHAJET") {
    firstPage.drawText(
      dashIfNoVerso(
        calculatorData.infos.back.varnish.toString() + " µm",
        calculatorData.doubleSided
      ) ?? "-",
      {
        x: 1090,
        y: 1979,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      dashIfNoVerso(
        calculatorData.infos.back.foil.toString() + " µm",
        calculatorData.doubleSided
      ) ?? "-",
      {
        x: 1090,
        y: 1879,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }

  machineType === "ALPHAJET" &&
    firstPage.drawText(
      dashIfZero(
        calculatorData.infos.back.opv ? round(totalCMYKPPercentBack) : 0,
        "%"
      ) ?? "-",
      {
        x: 1090,
        y: 1779,
        size: 24,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

  //CONSUMABLES COSTS

  if (machineType === "ALPHAJET") {
    firstPage.drawText(
      calculatorData.costs.cmykCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 2076,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.varnishCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 1976,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.foilCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 1876,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.substratCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 1776,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawText(
      calculatorData.costs.substratCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 2076,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.varnishCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 1976,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.antifoilCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 1876,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.foilCost.toString() +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2185,
        y: 1776,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      calculatorData.costs.foilRollSize.toString() + " m²" ?? "-",
      {
        x: 2185,
        y: 1676,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }

  //COVERAGE TABLE

  //RECTO PERCENT
  if (machineType === "ALPHAJET") {
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.cmyk.cyan.percent), "%") ??
        "-",
      {
        x: 905,
        y: 1453,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.front.cmyk.magenta.percent),
        "%"
      ) ?? "-",
      {
        x: 905,
        y: 1353,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.front.cmyk.yellow.percent),
        "%"
      ) ?? "-",
      {
        x: 905,
        y: 1253,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.front.cmyk.black.percent),
        "%"
      ) ?? "-",
      {
        x: 905,
        y: 1153,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(dashIfZero(totalCMYKPPercentFront, "%") ?? "-", {
      x: 905,
      y: 1053,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.front.varnish.percent) ?? 0,
        "%"
      ) ?? "-",
      {
        x: 905,
        y: 928,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(
          calculatorData.coverage.front.foil.reduce(
            (a: any, b: any) => parseFloat(a) + (parseFloat(b?.percent) ?? 0),
            0
          )
        ) ?? 0,
        "%"
      ) ?? "-",
      {
        x: 905,
        y: 803,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //ML RECTO
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.cmyk.cyan.ml), "ml") ??
        "-",
      {
        x: 1335,
        y: 1453,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.cmyk.magenta.ml), "ml") ??
        "-",
      {
        x: 1335,
        y: 1353,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.cmyk.yellow.ml), "ml") ??
        "-",
      {
        x: 1335,
        y: 1253,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.cmyk.black.ml), "ml") ??
        "-",
      {
        x: 1335,
        y: 1153,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(totalCMYKPConsoFront) ?? 0, "ml") ?? "-",
      {
        x: 1335,
        y: 1053,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.varnish.ml) ?? 0, "ml") ??
        "-",
      {
        x: 1335,
        y: 928,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(
          calculatorData.coverage.front.foil.reduce(
            (a: any, b: any) => a + (b?.ml ?? 0),
            0
          )
        ) ?? 0,
        "ml"
      ) ?? "-",
      {
        x: 1335,
        y: 803,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.front.varnish.percent) ?? 0,
        "%"
      ) ?? "-",
      {
        x: 1335,
        y: 1200,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.front.antifoil.percent) ?? 0,
        "%"
      ) ?? "-",
      {
        x: 1335,
        y: 1325,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.varnish.ml) ?? 0, "ml") ??
        "-",
      {
        x: 2205,
        y: 1200,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.front.antifoil.ml) ?? 0, "ml") ??
        "-",
      {
        x: 2205,
        y: 1325,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }

  if (machineType === "ALPHAJET") {
    //PERCENT VERSO

    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.back.cmyk.cyan.percent), "%") ??
        "-",
      {
        x: 1775,
        y: 1453,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.back.cmyk.magenta.percent),
        "%"
      ) ?? "-",
      {
        x: 1775,
        y: 1353,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.back.cmyk.yellow.percent),
        "%"
      ) ?? "-",
      {
        x: 1775,
        y: 1253,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.back.cmyk.black.percent), "%") ??
        "-",
      {
        x: 1775,
        y: 1153,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(dashIfZero(round(totalCMYKPPercentBack), "%") ?? "-", {
      x: 1775,
      y: 1053,
      size: 27,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(
      dashIfZero(
        round(calculatorData.coverage.back.varnish.percent) ?? 0,
        "%"
      ) ?? "-",
      {
        x: 1775,
        y: 928,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        round(
          calculatorData.coverage.back.foil.reduce(
            (a: any, b: any) => parseFloat(a) + (parseFloat(b?.percent) ?? 0),
            0
          )
        ) ?? 0,
        "%"
      ) ?? "-",
      {
        x: 1775,
        y: 803,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //ML VERSO
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.back.cmyk.cyan.ml), "ml") ?? "-",
      {
        x: 2205,
        y: 1453,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.back.cmyk.magenta.ml), "ml") ??
        "-",
      {
        x: 2205,
        y: 1353,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.back.cmyk.yellow.ml), "ml") ??
        "-",
      {
        x: 2205,
        y: 1253,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(calculatorData.coverage.back.cmyk.black.ml), "ml") ??
        "-",
      {
        x: 2205,
        y: 1153,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(round(totalCMYKPConsoBack) ?? 0, "ml") ?? "-",
      {
        x: 2205,
        y: 1053,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(calculatorData.coverage.back.varnish.ml ?? 0, "ml") ?? "-",
      {
        x: 2205,
        y: 928,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        calculatorData.coverage.back.foil.reduce(
          (a: any, b: any) => a + (b?.ml ?? 0),
          0
        ) ?? 0,
        "ml"
      ) ?? "-",
      {
        x: 2205,
        y: 803,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }
  //COST TABLE

  //VISU

  if (machineType === "ALPHAJET") {
    firstPage.drawText(
      (calculatorData.allCosts.visu.costOfMaterial.toFixed(6) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 905,
        y: 560,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.visu.costofCMYKPrint.toFixed(6)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 480,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.visu.costofVarnishUsed.toFixed(6)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 400,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.visu.foilCosts
            .reduce((a: any, b: any) => a + (b?.total ?? 0), 0)
            .toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 320,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.visu.finalOpvCost.toFixed(6)) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 240,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawText(
      (calculatorData.allCosts.visu.costOfMaterial.toFixed(6) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 905,
        y: 835,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.visu.costofVarnishUsed.toFixed(6)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 755,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.visu.costofAntifoilUsed.toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 675,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.visu.foilCosts
            .reduce((a: any, b: any) => a + (b?.film ?? 0), 0)
            .toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 905,
        y: 595,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }
  //PAGE

  if (machineType === "ALPHAJET") {
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.page.costOfMaterial.toFixed(3)) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 560,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.page.costofCMYKPrint.toFixed(3)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 480,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.page.costofVarnishUsed.toFixed(3)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 400,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.page.foilCosts
            .reduce((a: any, b: any) => a + (b?.total ?? 0), 0)
            .toFixed(3)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 320,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.page.finalOpvCost.toFixed(3)) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 240,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.page.costOfMaterial.toFixed(6)) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 835,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.page.costofVarnishUsed.toFixed(6)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 755,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.page.costofAntifoilUsed.toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ),
      {
        x: 1485,
        y: 675,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.page.foilCosts
            .reduce((a: any, b: any) => a + (b?.film ?? 0), 0)
            .toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 1485,
        y: 595,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }
  //TOTAL

  if (machineType === "ALPHAJET") {
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.total.costOfMaterial.toFixed(3)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 560,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.total.costofCMYKPrint.toFixed(3)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 480,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.total.costofVarnishUsed.toFixed(3)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 400,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.total.foilCosts
            .reduce((a: any, b: any) => a + (b?.total ?? 0), 0)
            .toFixed(3)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 320,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.total.finalOpvCost.toFixed(3)) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 240,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawText(
      dashIfZero(
        parseFloat(calculatorData.allCosts.total.costOfMaterial.toFixed(6)) ??
          0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 835,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.total.costofVarnishUsed.toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 755,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.total.costofAntifoilUsed.toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 675,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      dashIfZero(
        parseFloat(
          calculatorData.allCosts.total.foilCosts
            .reduce((a: any, b: any) => a + (b?.film ?? 0), 0)
            .toFixed(6)
        ) ?? 0,
        calculatorData.costs.devise
      ) ?? "-",
      {
        x: 2065,
        y: 595,
        size: 27,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }

  //TOTAL TABLE

  if (machineType === "ALPHAJET") {
    //VISU
    firstPage.drawText(
      (calculatorData.allCosts.visu.totalCostofJob.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 905,
        y: 100,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //PAGE
    firstPage.drawText(
      (calculatorData.allCosts.page.totalCostofJob.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 1485,
        y: 100,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //TOTAL
    firstPage.drawText(
      (calculatorData.allCosts.total.totalCostofJob.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2065,
        y: 100,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawRectangle({
      width: 400,
      height: 50,
      x: 150,
      y: 440,
      color: rgb(1, 1, 1),
    });

    firstPage.drawText(`${lang === "fr" ? "Coût m²" : "Sqm cost"}`, {
      x: 300,
      y: 460,
      size: 32,
      font: helveticaFont,
      color: rgb(0.44, 0.44, 0.44),
    });

    firstPage.drawText(
      (calculatorData.costs.sqmPrice.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 1485,
        y: 460,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //VISU
    firstPage.drawText(
      (calculatorData.allCosts.visu.totalCostofJob.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 905,
        y: 320,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //PAGE
    firstPage.drawText(
      (calculatorData.allCosts.page.totalCostofJob.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 1485,
        y: 320,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    //TOTAL
    firstPage.drawText(
      (calculatorData.allCosts.total.totalCostofJob.toFixed(3) ?? 0) +
        " " +
        calculatorData.costs.devise ?? "-",
      {
        x: 2065,
        y: 320,
        size: 29,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }

  const pdfBytes = await pdfDoc.save();
  download(
    pdfBytes,
    //`ALPHAJET_ROI_${allInputs.roiInfo?.roiName}.pdf`,
    `${
      (calculatorData.name === ""
        ? lang === "fr"
          ? "NOM_DU_PROJET"
          : "PROJECT_NAME"
        : calculatorData.name
      ).split(".pdf")[0]
    }_${lang === "fr" ? "estimation" : "estimate"}.pdf`,
    "application/pdf"
  );
}

function dashIfZero(value: number, unit: string) {
  if (value === 0) return "   -";
  else {
    return value.toString() + " " + unit;
  }
}

function dashIfNoVerso(value: string, verso: boolean) {
  return verso ? value : "   -";
}

function getPdf(lang: string, type: MachineModelType) {
  const baseUrl = "/pdf/Export-PDF";
  const langUrl = lang.includes("fr") ? "FR" : "EN";
  const typeUrl = type.toUpperCase();

  return `${baseUrl}-${langUrl}-${typeUrl}.pdf`;
}
