import { MachineModelType } from "@mgi-labs/lib-liste-machine";
import { OpvType } from "../../calculatorTypes";
import { FoilsData, PdfPreflightResponse } from "../../pdfPreflight.interface";
import { FaceInfos, FoilInformation, RoiParams } from "./staticInput";

export type ConsoPerFace = {
  front: {
    cmyk: number;
    varnish: number;
    foil: { name: string; value: number }[];
    opv: number;
    antifoil?: number;
  };
  back: {
    cmyk: number;
    varnish: number;
    foil: { name: string; value: number }[];
    opv: number;
  };
};

export type FinalSqmFace = {
  name: string;
  foilSurfaces: FoilsData[] | undefined;
  sqmTotal: number;
};

export type FaceCoverage = {
  page: number;
  cmykPercent: number;
  antifoilPercent: number;
  foilPercent: { name: string; value: number }[];
  varnishPercent: number;
  opvPercent: number;
};

export type CostPerType = {
  costOfMaterial: number;
  costofCMYKPrint: number;
  costofPrimer: number;
  costofVarnishUsed: number;
  costofAntifoilUsed?: number;
  finalOpvCost: number;
  foilCosts: {
    name: string;
    film: number;
    varnish: number;
    total: number;
  }[];
  totalCostofJob: number;
};

export type SqmFace = {
  sqm:
    | {
        name: string;
        value: number;
      }[]
    | undefined;
  surface:
    | {
        name: string;
        value: FoilsData[];
      }[]
    | undefined;
};

export type RoiResult = {
  totalNOSheetByJob: number;
  costPerVisu: CostPerType;
  conso: ConsoPerFace;
  costPerPage: CostPerType;
  totalCost: CostPerType;
  nbPage: number;
  sqmFront: FinalSqmFace[] | undefined;
  sqmBack: FinalSqmFace[] | undefined;
};

export default function roiResult(
  facesCoverage: FaceCoverage[],
  roiParams: RoiParams,
  upsPerSheet: number,
  sheetNumber: number,
  finalHeight: number,
  finalWidth: number,
  opvType: OpvType,
  foilList: string[],
  preflight: PdfPreflightResponse[] | undefined,
  machineType: MachineModelType | undefined,
  association:
    | {
        varnish: string | undefined;
        antifoil: string | undefined;
      }
    | undefined
): RoiResult {
  const totalNOSheetByJob = roiParams.numberOfSheets;

  const pageNumber = sheetNumber;

  const sqmDimension =
    (roiParams.paperFormat.width * roiParams.paperFormat.length) / 1000000;

  const costOfMaterialPerSheet =
    machineType === "COMPACT"
      ? sqmDimension * roiParams.paperCostForJob
      : roiParams.paperCostForJob;

  const internalEcoConso = roiParams.isInternalEcoConso ? 0.8 : 1;

  const doubleSided = facesCoverage.length > 1;

  const frontCoverage = facesCoverage.find((f) => f.page === 0);
  const backCoverage = facesCoverage.find((f) => f.page === 1);

  const costofCMYKPrintFront =
    roiParams.alphaJetGlobalPrintingCosts.costPerLiterB1 *
    roiParams.alphaJetGlobalPrintingCosts.costPerInkLiter *
    ((frontCoverage?.cmykPercent ?? 0) / 100) *
    internalEcoConso *
    totalNOSheetByJob;

  const CMYKPrintFront =
    roiParams.alphaJetGlobalPrintingCosts.costPerLiterB1 *
    ((frontCoverage?.cmykPercent ?? 0) / 100) *
    internalEcoConso *
    totalNOSheetByJob;

  const costofCMYKPrintBack =
    roiParams.alphaJetGlobalPrintingCosts.costPerLiterB1 *
    roiParams.alphaJetGlobalPrintingCosts.costPerInkLiter *
    ((backCoverage?.cmykPercent ?? 0) / 100) *
    internalEcoConso *
    totalNOSheetByJob;

  const CMYKPrintBack =
    roiParams.alphaJetGlobalPrintingCosts.costPerLiterB1 *
    ((backCoverage?.cmykPercent ?? 0) / 100) *
    internalEcoConso *
    totalNOSheetByJob;

  const tempCostCMYKFront =
    (costofCMYKPrintFront *
      (((roiParams.imageFormat.length * roiParams.imageFormat.width) / 700) *
        1000)) /
    1000000;

  const consoCMYKFront =
    (CMYKPrintFront *
      (((roiParams.imageFormat.length * roiParams.imageFormat.width) / 700) *
        1000)) /
    1000000;
  const tempCostCMYKBack =
    (costofCMYKPrintBack *
      (((roiParams.imageFormat.length * roiParams.imageFormat.width) / 700) *
        1000)) /
    1000000;

  const consoCMYKBack =
    (CMYKPrintBack *
      (((roiParams.imageFormat.length * roiParams.imageFormat.width) / 700) *
        1000)) /
    1000000;

  const finalCostOfCMYKPrint = doubleSided
    ? tempCostCMYKFront + tempCostCMYKBack
    : tempCostCMYKFront;

  const costOfPrimer =
    (roiParams.primerCost / 1360) *
    ((((roiParams.imageFormat.length * roiParams.imageFormat.width) / 700) *
      1000) /
      1000000);

  const finalCostOfPrimer = (doubleSided ? 2 : 1) * costOfPrimer;

  const finalFoilCost = getFinalFoilCost();

  const costofVarnishUsedFront = getFaceVarnishCost(
    roiParams.front,
    frontCoverage,
    preflight?.[0]
  );

  const costofAntifoilUsed = getAntifoilCost(
    roiParams.front,
    preflight?.[0],
    association
  );

  const costofVarnishUsedBack = getFaceVarnishCost(
    roiParams.back,
    backCoverage
  );

  const finalCostOfVarnishUsed = doubleSided
    ? costofVarnishUsedFront.cost + costofVarnishUsedBack.cost
    : costofVarnishUsedFront.cost;

  const opvCoverageFront =
    opvType === "spot"
      ? frontCoverage?.cmykPercent
      : opvType === "file"
      ? frontCoverage?.opvPercent
      : 100;

  const opvCoverageBack =
    opvType === "spot"
      ? backCoverage?.cmykPercent
      : opvType === "file"
      ? backCoverage?.opvPercent
      : 100;

  const opvCostFront = getFaceOpv(
    roiParams.front.opv ? opvCoverageFront ?? 0 : 0,
    roiParams.front.opv2d3d ?? 3
  );
  const opvCostBack = getFaceOpv(
    roiParams.back.opv ? opvCoverageBack ?? 0 : 0,
    roiParams.back.opv2d3d ?? 3
  );

  const finalOpvCost =
    machineType === "ALPHAJET"
      ? doubleSided
        ? opvCostFront.cost + opvCostBack.cost
        : opvCostFront.cost
      : 0;

  function getFaceVarnishCost(
    face: FaceInfos,
    faceCoverage: FaceCoverage | undefined,
    preflight?: PdfPreflightResponse
  ) {
    const g18 =
      (roiParams.imageFormat.length *
        roiParams.imageFormat.width *
        roiParams.numberOfSheets) /
      1000000;

    const percent = preflight?.otherColor.find(
      (e) => e.name === (association?.varnish ?? "")
    )?.percent;

    const g19 = g18 * ((percent ?? faceCoverage?.varnishPercent ?? 0) / 100);

    const g21 = g19 / getSurfaceVarnish(face.embellishmentUv2d3dEffect);

    const conso = g21;

    return {
      conso: conso,
      cost: roiParams.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter * conso,
    };
  }
  function getAntifoilCost(
    face: FaceInfos,
    preflight: PdfPreflightResponse | undefined,
    association:
      | { varnish: string | undefined; antifoil: string | undefined }
      | undefined
  ) {
    const g18 =
      (roiParams.imageFormat.length *
        roiParams.imageFormat.width *
        roiParams.numberOfSheets) /
      1000000;

    const percent = preflight?.otherColor.find(
      (e) => e.name === (association?.antifoil ?? "")
    )?.percent;

    const g19 = g18 * ((percent ?? 0) / 100);

    const g21 = g19 / getSurfaceVarnish(face?.antifoil2d3dEffect ?? 1);

    const conso = g21;

    return {
      conso: conso,
      cost: conso * roiParams.alphaJetGlobalPrintingCosts.costPerAntifoilLiter,
    };
  }

  function getFaceOpv(coverage: number, effect: number) {
    const g18 = (finalHeight * finalWidth * roiParams.numberOfSheets) / 1000000;
    const opvCost = (g18 * (coverage / 100)) / getSurfaceVarnish(effect);
    const conso = opvCost;

    return {
      conso: conso,
      cost: conso * roiParams.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter,
    };
  }

  function getSurfaceVarnish(thickness: number): number {
    return Math.round(995.4 / thickness);
  }

  const foilCosts = dataFoilCost(
    roiParams.front,
    roiParams.back,
    roiParams.foilInformation,
    roiParams.isSqm,
    roiParams.sqmPrice,
    doubleSided,
    sheetNumber,
    frontCoverage,
    backCoverage,
    roiParams.imageFormat.length,
    roiParams.imageFormat.width,
    roiParams.foilRollSize,
    foilList,
    preflight,
    machineType === "ALPHAJET"
  );

  function getFinalFoilCost() {
    const g18 =
      (roiParams.imageFormat.length *
        roiParams.imageFormat.width *
        roiParams.numberOfSheets) /
      1000000;

    const g19Front = frontCoverage?.foilPercent.map((p) => {
      return { name: p.name, value: g18 * ((p.value ?? 0) / 100) };
    });
    const g19Back = backCoverage?.foilPercent.map((p) => {
      return { name: p.name, value: g18 * ((p.value ?? 0) / 100) };
    });

    const coverageFront = g19Front?.map((c) => {
      return {
        name: c.name,
        value:
          c.value / getSurfaceVarnish(roiParams.front.digitalHotFoil2d3dEffect),
      };
    });
    const coverageBack = g19Back?.map((c) => {
      return {
        name: c.name,
        value:
          c.value / getSurfaceVarnish(roiParams.back.digitalHotFoil2d3dEffect),
      };
    });

    const costofVarnishUsedFront = coverageFront?.map((c) => {
      return {
        name: c.name,
        value: {
          conso: c.value,
          cost:
            roiParams.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter *
            c.value,
        },
      };
    });
    const costofVarnishUsedBack = coverageBack?.map((c) => {
      return {
        name: c.name,
        value: {
          conso: c.value,
          cost:
            roiParams.alphaJetGlobalPrintingCosts.costPerUvVarnishLiter *
            c.value,
        },
      };
    });

    return [frontCoverage, backCoverage].flatMap((e) =>
      e?.foilPercent.map((f) => {
        const front = costofVarnishUsedFront?.find((c) => c.name === f.name);
        const back = costofVarnishUsedBack?.find((c) => c.name === f.name);
        return {
          front: front,
          back: back,
          total: doubleSided
            ? (frontCoverage?.foilPercent.find((c) => f.name === c.name)
                ?.value ?? 0) > 0 ||
              (backCoverage?.foilPercent.find((c) => f.name === c.name)
                ?.value ?? 0) > 0
              ? (front?.value.cost ?? 0) + (back?.value.cost ?? 0)
              : 0
            : (frontCoverage?.foilPercent.find((c) => f.name === c.name)
                ?.value ?? 0) > 0
            ? front?.value.cost
            : 0,
        };
      })
    );
  }

  const totalFinalFoilCost =
    finalFoilCost?.reduce((a, b) => a + (b?.total ?? 0), 0) ?? 0;

  const totalCostofJob =
    (machineType === "ALPHAJET" ? finalCostOfVarnishUsed : 0) +
    costOfMaterialPerSheet +
    (machineType === "ALPHAJET" ? finalCostOfPrimer : 0) +
    (machineType === "ALPHAJET" ? finalCostOfCMYKPrint : 0) +
    finalOpvCost +
    (association?.varnish || machineType === "ALPHAJET"
      ? foilCosts.costPerJobIn.reduce((a, b) => a + b.value, 0)
      : 0) +
    totalFinalFoilCost +
    (machineType === "COMPACT" ? costofAntifoilUsed.cost : 0);
  return {
    totalNOSheetByJob: totalNOSheetByJob,
    costPerVisu: {
      costOfMaterial: costOfMaterialPerSheet / upsPerSheet,
      costofCMYKPrint: finalCostOfCMYKPrint / upsPerSheet,
      costofPrimer: finalCostOfPrimer / upsPerSheet,
      costofVarnishUsed: finalCostOfVarnishUsed / upsPerSheet,
      costofAntifoilUsed: costofAntifoilUsed.cost / upsPerSheet,
      finalOpvCost: finalOpvCost / upsPerSheet,
      foilCosts: foilCosts.costPerJobIn.map((f) => {
        return {
          name: f.name,
          film: f.value / upsPerSheet,
          varnish: totalFinalFoilCost / upsPerSheet,
          total: (f.value + totalFinalFoilCost) / upsPerSheet,
        };
      }),
      totalCostofJob: totalCostofJob / upsPerSheet,
    },
    conso: {
      front: {
        cmyk: consoCMYKFront,
        foil: finalFoilCost?.map((f) => {
          return {
            name: f?.front?.name ?? "",
            value: f?.front?.value.conso ?? 0,
          };
        }),
        varnish: costofVarnishUsedFront.conso,

        antifoil: costofAntifoilUsed.conso,
        opv: opvCostFront.conso,
      },
      back: {
        cmyk: consoCMYKBack,
        foil: finalFoilCost?.map((f) => {
          return {
            name: f?.back?.name ?? "",
            value: f?.back?.value.conso ?? 0,
          };
        }),
        varnish: costofVarnishUsedBack.conso,
        opv: opvCostBack.conso,
      },
    },
    costPerPage: {
      costOfMaterial: costOfMaterialPerSheet,
      costofCMYKPrint: finalCostOfCMYKPrint,
      costofPrimer: finalCostOfPrimer,
      costofVarnishUsed: finalCostOfVarnishUsed,
      costofAntifoilUsed: costofAntifoilUsed.cost,
      finalOpvCost: finalOpvCost,
      foilCosts: foilCosts.costPerJobIn.map((f) => {
        return {
          name: f.name,
          film: f.value,
          varnish: totalFinalFoilCost,
          total: f.value + totalFinalFoilCost,
        };
      }),

      totalCostofJob: totalCostofJob,
    },
    totalCost: {
      costOfMaterial: costOfMaterialPerSheet * sheetNumber,
      costofCMYKPrint: finalCostOfCMYKPrint * sheetNumber,
      costofPrimer: finalCostOfPrimer * sheetNumber,
      costofVarnishUsed: finalCostOfVarnishUsed * sheetNumber,
      costofAntifoilUsed: costofAntifoilUsed.cost * sheetNumber,
      finalOpvCost: finalOpvCost * sheetNumber,
      foilCosts: foilCosts.costPerJobIn.map((f) => {
        return {
          name: f.name,
          film: f.value * sheetNumber,
          varnish: totalFinalFoilCost * sheetNumber,
          total: (f.value + totalFinalFoilCost) * sheetNumber,
        };
      }),
      totalCostofJob: totalCostofJob * sheetNumber,
    },
    nbPage: pageNumber,
    sqmFront: foilCosts.sqmFront,
    sqmBack: foilCosts.sqmBack,
  };
}

function dataFoilCost(
  front: FaceInfos,
  back: FaceInfos,
  foilInformation: FoilInformation,
  isSqm: boolean,
  sqmPrice: number,
  doubleSided: boolean,
  numberOfSheets: number,
  frontCoverage: FaceCoverage | undefined,
  backCoverage: FaceCoverage | undefined,
  finalHeight: number,
  finalWidth: number,
  foilRollSize: number,
  foilList: string[],
  preflight: PdfPreflightResponse[] | undefined,
  isAlphajet: boolean
) {
  const frontPreflightFoils = preflight?.find((p) => p.page === 1)?.foils;
  const backPreflightFoils = preflight?.find((p) => p.page === 2)?.foils;

  const sqmFront =
    front.digitalHotFoil2d3dEffect > 0
      ? frontCoverage?.foilPercent.map((f) => {
          return {
            name: f.name,
            value:
              f.value > 0
                ? ((isAlphajet
                    ? frontPreflightFoils?.find((fp) => fp.name === f.name)
                        ?.surface
                    : finalHeight * foilRollSize) ?? 0) / 1000000 ?? 0
                : 0,
          };
        })
      : [];

  const surfacesFront =
    front.digitalHotFoil2d3dEffect > 0
      ? frontCoverage?.foilPercent.map((f) => {
          return {
            name: f.name,
            value:
              frontPreflightFoils?.find((fp) => fp.name === f.name)?.foils ??
              [],
          };
        })
      : [];

  const sqmBack =
    back.digitalHotFoil2d3dEffect > 0
      ? backCoverage?.foilPercent.map((f) => {
          return {
            name: f.name,
            value:
              f.value > 0
                ? (backPreflightFoils?.find((fp) => fp.name === f.name)
                    ?.surface ?? 0) / 1000000 ?? 0
                : 0,
          };
        })
      : [];

  const surfacesBack =
    back.digitalHotFoil2d3dEffect > 0
      ? backCoverage?.foilPercent.map((f) => {
          return {
            name: f.name,
            value:
              backPreflightFoils?.find((fp) => fp.name === f.name)?.foils ?? [],
          };
        })
      : [];

  const sqmFrontArray: FinalSqmFace[] = [];

  sqmFront?.forEach((e) => {
    const foilSurfaces = surfacesFront
      ?.filter((f) => f.name === e.name)
      .map((g) => g.value);
    sqmFrontArray.push({
      name: e.name,
      foilSurfaces: foilSurfaces?.flat(),
      sqmTotal: e.value,
    });
  });

  const sqmBackArray: FinalSqmFace[] = [];

  if (isAlphajet) {
    sqmBack?.forEach((e) => {
      const foilSurfaces = surfacesBack
        ?.filter((f) => f.name === e.name)
        .map((g) => g.value);
      sqmBackArray.push({
        name: e.name,
        foilSurfaces: foilSurfaces?.flat(),
        sqmTotal: e.value,
      });
    });
  }

  const finalFoilCost = foilList.map((f) => {
    return doubleSided
      ? {
          name: f,
          value:
            ((sqmFront?.find((s) => s.name === f)?.value ?? 0) +
              (sqmBack?.find((s) => s.name === f)?.value ?? 0)) *
            sqmPrice,
        }
      : {
          name: f,
          value: (sqmFront?.find((s) => s.name === f)?.value ?? 0) * sqmPrice,
        };
  });

  const ret = {
    costPerSquare: sqmPrice,
    sQMofFoilUsedper: 0,
    costofFoilPerSheetin: 0,
    costPerJobIn: finalFoilCost,
    sqmFront: sqmFrontArray,
    sqmBack: sqmBackArray,
  };

  return ret;
}
