import React from "react";
import { formatNumber } from "./calculator/calculator";
import { useTranslation } from "react-i18next";
import { Devise } from "../calculatorTypes";
import { RoiResult } from "./calculator/roiResult";
import { PdfPreflightResponse } from "../pdfPreflight.interface";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

const TableFoilCostCompact = ({
  devise,
  foil,
  roiCostPrint,
}: {
  devise: Devise;
  foil: string;
  roiCostPrint: RoiResult;
  preflight: PdfPreflightResponse[] | undefined;
  machineType: MachineModelType | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <>
        <tr key={foil}>
          <td>
            {t("clearVarnish", {
              foil: foil,
            })}
          </td>

          <td>
            {formatNumber(
              roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
                ?.varnish ?? 0,
              6
            )}{" "}
            {devise}
          </td>
          <td>
            {formatNumber(
              roiCostPrint.costPerPage.foilCosts.find((f) => f.name === foil)
                ?.varnish ?? 0,
              6
            )}{" "}
            {devise}
          </td>
          <td>
            {formatNumber(
              roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
                ?.varnish ?? 0,
              6
            )}{" "}
            {devise}
          </td>
        </tr>
        <tr>
          <td>
            {t("compactFoilFilm", {
              foil: foil,
            })}
          </td>

          <td>
            {formatNumber(
              roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
                ?.film ?? 0,
              6
            )}{" "}
            {devise}
          </td>
          <td>
            {formatNumber(
              roiCostPrint.costPerPage.foilCosts.find((f) => f.name === foil)
                ?.film ?? 0,
              6
            )}{" "}
            {devise}
          </td>
          <td style={{ position: "relative" }}>
            {formatNumber(
              roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
                ?.film ?? 0,
              6
            )}{" "}
            {devise}
          </td>
        </tr>
      </>
    </>
  );
};

export default TableFoilCostCompact;
